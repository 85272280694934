import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import DiscoverBondCard from "./DiscoverBondCard";
import { BannerData } from "@helpers/homepageData";

const DiscoverBonds = ({ horizontalLayoutForTablet }) => {
    const gridColsClassName = horizontalLayoutForTablet ? 'grid-cols-1 md:grid-cols-3' : 'grid-cols-1 xl:grid-cols-3';
    return (
        <>
            <Text content={texts?.DiscoverBonds} className="text-gray-900 p3-semibold md:h4-semibold xl:h3-semibold mt-[137px] md:mt-[129px] xl:mt-[111px]" />
            <div className={`grid ${gridColsClassName} gap-y-4 gap-x-6 mt-4 md:mt-6 w-full ${horizontalLayoutForTablet ? 'md:w-full' : 'md:w-[60%]'} xl:w-3/4`}>
                {BannerData?.map((item, index) => <DiscoverBondCard key={index} header={item.header} description={item.description} imageSrc={item.image} horizontalLayoutForTablet={horizontalLayoutForTablet} />)}
            </div>
        </>);
}

export default DiscoverBonds;