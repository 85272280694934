import Image from "@atoms/Image";
import Text from "@atoms/Text";

const DiscoverBondCard = ({ imageSrc, header, description, horizontalLayoutForTablet }) => {
    return (
        <div className={`flex flex-row ${horizontalLayoutForTablet ? 'md:flex-col' : 'xl:flex-col'} gap-x-4 p-4 md:p-6 rounded-xl bg-basicWhite/60 shadow-home-page-banner-card`}>
            <div className={`flex flex-row gap-x-4  ${horizontalLayoutForTablet ? 'md:items-center' : 'xl:items-center'}`}>
                <Image src={imageSrc} alt={header} className="w-12 h-12" />
                <div>
                    <Text content={header} className="h4-semibold text-primary-500" />
                    <Text content={description} className={`${horizontalLayoutForTablet ? 'md:hidden' : 'xl:hidden'} mt-1 p3-regular text-basicBlack`} />
                </div>
            </div>
            <Text content={description} className={`hidden ${horizontalLayoutForTablet ? 'md:block' : 'xl:block'} mt-4 p3-regular text-basicBlack`} />
        </div>
    );
}

export default DiscoverBondCard;